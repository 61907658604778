import cx from 'classnames'
import React from 'react'
import { useUserSession } from '../../../routing/SessionContext'
import styles from './menus.module.scss'

const UserMenu = () => {
  const { handleSignout, handleEditProfile } = useUserSession()
  return (
    <>
      {handleEditProfile && (
        <div
          className={cx(
            styles.optionsContainer,
            styles.dropdownButton,
            styles.menuSection
          )}
          onClick={handleEditProfile}
          data-testid="um-manage-profile"
        >
          Manage Profile
        </div>
      )}
      <div
        className={cx(
          styles.optionsContainer,
          styles.dropdownButton,
          styles.menuSection
        )}
        onClick={handleSignout}
        data-testid="um-sign-out"
      >
        Sign Out
      </div>
    </>
  )
}

export default UserMenu

import {
  ApiCallState,
  callFailed,
  callInitiated,
  callSucceeded
} from '../../types'
import { toggleElement } from '../helpers'
import { WatchList } from '../watchList/types'
import { ListTradingAction } from './actions'
import { securityToOrder, updateArray } from './helpers'
import {
  CREATELISTTRADINGLIST,
  DELETESECURITYFROMLISTSUCCESS,
  LISTTRADINGSECURITIES_FETCH,
  LISTTRADINGSECURITIES_FETCH_FAIL,
  LISTTRADINGSECURITIES_FETCH_SUCCESS,
  ListTradingSecurity,
  RESETLISTTRADINGTRANSACTIONID,
  SETLISTTRADINGTRANSACTIONID,
  UPDATELISTTRADINGLISTSUCCESS,
  WorkingListTradingOrder
} from './types'

type WorkingSecurityHash = Record<
  ListTradingSecurity['id'],
  WorkingListTradingOrder
>

type SpecialWatchlistId = 'new' | 'invalidWatchlist'

export interface State {
  securitiesList: ListTradingSecurity[]
  pending: boolean
  error: boolean
  newWatchlistTransactionId: number
  createListState?: ApiCallState
  workingSecurities: WorkingSecurityHash
  watchlistId: WatchList['id'] | SpecialWatchlistId
}

const workingSecurities: WorkingSecurityHash = {}

export const initialState: State = {
  securitiesList: [],
  pending: true,
  error: false,
  newWatchlistTransactionId: 0,
  workingSecurities,
  watchlistId: undefined
}

const addWorkingSecurities = (
  prev: WorkingSecurityHash,
  updates: ListTradingSecurity[],
  isTemp: boolean
) => {
  const result = { ...prev }
  for (const update of updates) {
    const selectedOrders = prev[update.id]?.selectedOrders ?? []
    const ordersToCancel = prev[update.id]?.ordersToCancel ?? []
    result[update.id] = {
      ...securityToOrder(update, update.id, isTemp),
      ordersToCancel,
      selectedOrders
    }
  }
  return result
}

const updateWorkingOrders = (
  prev: WorkingSecurityHash,
  updates: WorkingListTradingOrder[],
  isTemp: boolean = false
) => {
  const result = { ...prev }
  for (const update of updates) {
    result[update.securityId] = {
      ...update,
      selectedOrders: update.selectedOrders ?? [],
      isTemp
    }
  }
  return result
}

export const reducer = (
  state = initialState,
  action: ListTradingAction
): State => {
  switch (action.type) {
    case CREATELISTTRADINGLIST:
      return { ...state, createListState: callInitiated }
    case 'listTrading.createListFailure':
      return {
        ...state,
        createListState: { ...callFailed, status: action.payload.message }
      }

    case 'listTrading.clearCreateListTradingListError':
      const { createListState, ...rest } = state
      return {
        ...rest
      }

    case LISTTRADINGSECURITIES_FETCH:
      return {
        ...state,
        pending: true,
        error: false,
        securitiesList: [],
        workingSecurities
      }

    case LISTTRADINGSECURITIES_FETCH_FAIL:
      return {
        ...state,
        pending: false,
        error: true
      }

    case LISTTRADINGSECURITIES_FETCH_SUCCESS:
      return {
        ...state,
        securitiesList: updateArray(state.securitiesList, action.payload),
        workingSecurities: addWorkingSecurities(
          state.workingSecurities,
          action.payload,
          false
        ),
        ...callSucceeded
      }

    case 'listTrading.createWorkingListTradingOrder':
      // watchlist id used in epic, discard and just use the working order
      const { watchlistId, ...order } = action.payload
      const prevOrder = state.workingSecurities[order.securityId]
      const isTemp = prevOrder?.isTemp ?? true
      return {
        ...state,
        workingSecurities: {
          ...state.workingSecurities,
          [action.payload.securityId]: { ...order, isTemp }
        }
      }

    case 'listTrading.selectListTradingSecurities':
      const newWorkingSecurities: WorkingSecurityHash = {}
      const securityIds = Object.keys(state.workingSecurities)
      securityIds.forEach((securityId) => {
        const workingSecurity = {
          ...state.workingSecurities[Number(securityId)]
        }
        if (action.payload.includes(Number(securityId))) {
          if (!workingSecurity.selectedOrders.length) {
            const matchingSecurity = state.securitiesList.find(
              (security) => security.id === Number(securityId)
            )
            if (matchingSecurity) {
              const bestTrade = matchingSecurity.bestOrder
              workingSecurity.selectedOrders = bestTrade ? [bestTrade.id] : []
            }
          }
        } else {
          workingSecurity.selectedOrders = []
        }
        newWorkingSecurities[Number(securityId)] = workingSecurity
      })
      return {
        ...state,
        workingSecurities: newWorkingSecurities
      }

    case 'listTrading.toggleSuborderSelection': {
      const ws = state.workingSecurities[action.payload.securityId]
      return {
        ...state,
        workingSecurities: {
          ...state.workingSecurities,
          [action.payload.securityId]: {
            ...ws,
            selectedOrders: toggleElement(
              ws.selectedOrders,
              action.payload.orderId
            )
          }
        }
      }
    }

    case 'listTrading.toggleCancelSubOrderSelection': {
      const ws = state.workingSecurities[action.payload.securityId]
      return {
        ...state,
        workingSecurities: {
          ...state.workingSecurities,
          [action.payload.securityId]: {
            ...ws,
            ordersToCancel: toggleElement(
              ws.ordersToCancel,
              action.payload.orderId
            )
          }
        }
      }
    }

    case 'listTrading.tradeSelectedSecurities': {
      return {
        ...state,
        securitiesList: updateArray(
          state.securitiesList,
          state.securitiesList.map((s) => ({ ...s, error: '' }))
        )
      }
    }

    case 'listTrading.setListTradeErrors': {
      return {
        ...state,
        securitiesList: updateArray(state.securitiesList, action.payload)
      }
    }

    /*
        Note the reason there is no action to select and deselect
        all orders on all security ids is because the pending orders
        live in store/order, not here.
     */
    case 'listTrading.selectSecurityOrdersToCancel': {
      const ws = state.workingSecurities[action.payload.securityId]
      return {
        ...state,
        workingSecurities: {
          ...state.workingSecurities,
          [action.payload.securityId]: {
            ...ws,
            ordersToCancel: action.payload.orderIds
          }
        }
      }
    }

    case 'listTrading.deselectSecurityOrdersToCancel': {
      const ws = state.workingSecurities[action.payload.securityId]
      return {
        ...state,
        workingSecurities: {
          ...state.workingSecurities,
          [action.payload.securityId]: {
            ...ws,
            ordersToCancel: []
          }
        }
      }
    }

    case 'listTrading.setListTradingWatchlistId': {
      return { ...state, watchlistId: action.payload }
    }

    case UPDATELISTTRADINGLISTSUCCESS:
      return {
        ...state,
        workingSecurities: updateWorkingOrders(
          state.workingSecurities,
          action.payload,
          false
        )
      }

    case SETLISTTRADINGTRANSACTIONID:
      return {
        ...state,
        newWatchlistTransactionId: action.payload
      }

    case RESETLISTTRADINGTRANSACTIONID:
      return {
        ...state,
        newWatchlistTransactionId: 0
      }
    case DELETESECURITYFROMLISTSUCCESS:
      return {
        ...state,
        securitiesList: state.securitiesList.filter(
          (sec) => !(sec.id === action.payload)
        )
      }

    default:
      return state
  }
}
